import * as bootstrap from "bootstrap";
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

document.addEventListener('DOMContentLoaded', function() {
    const locationInput = document.getElementById('location-input');
    const locationDropdown = document.getElementById('location-dropdown');
    const citySelect = document.getElementById('city-select');
    const regionSelect = document.getElementById('region-select');

    locationInput.addEventListener('click', function() {
        locationDropdown.style.display = 'block';
    });

    document.addEventListener('click', function(event) {
        if (!locationInput.contains(event.target) && !locationDropdown.contains(event.target)) {
            locationDropdown.style.display = 'none';
        }
    });

    regionSelect.addEventListener('change', function() {
        const selectedRegion = regionSelect.options[regionSelect.selectedIndex].text;

        updateLocationInput();
    });

    citySelect.addEventListener('change', function() {
        const selectedCity = citySelect.options[citySelect.selectedIndex].text;

        updateLocationInput();
    });


    function updateLocationInput() {
        const region = regionSelect.options[regionSelect.selectedIndex] ? regionSelect.options[regionSelect.selectedIndex].text : '';
        const city = citySelect.options[citySelect.selectedIndex] ? citySelect.options[citySelect.selectedIndex].text : '';

        locationInput.value = `${region}, ${city}`;
    }
});
